import React, { useEffect, useState } from "react";
import HomeCard from "../components/HomeCard/HomeCard";
import image from "../images/homepage_bg.png";


const Homepage = () => {
  const [num, setNum] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function randomNumberInRange(min, max) {
    // 👇️ get number between min (inclusive) and max (inclusive)
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  useEffect(() => {
    setNum(randomNumberInRange(1, 3));
  }, []);

  return (
    <>
   
      <div className= {`randomNum ${isMobile ? `mobile${num}` : `randomNum${num}`}`}>
        <HomeCard url={"/collaborate"} title={"COLLAB\nO\nRATE"} />
        <HomeCard url={"converge"} title={"con\nverge"} />
        <HomeCard url={"/"} title={"ERGOSUMDEUS"} />
        <HomeCard url={"/communicate"} title={"COMM\nUNI\nCATE"} />
        <HomeCard url={"/cogitate"} title={"COG\nI\nTATE"} />
      </div>
       
      <div style={{  
        backgroundImage: "url(" + image + ")",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100vh'
      }} className= {`randomNum_mobile ${isMobile ? `mobile${num}` : `randomNum${num}`}`}>
        <div className="home_mobile_nav">
         <HomeCard url={"/"} title={"ERGOSUMDEUS"} />
        <HomeCard url={"/collaborate"} title={"COLLABORATE"} />
        <HomeCard url={"converge"} title={"converge"} />       
        <HomeCard url={"/communicate"} title={"COMMUNICATE"} />
        <HomeCard url={"/cogitate"} title={"COGITATE"} />
        <HomeCard url={"/"} title={"Exit"} />
        </div>
        <div className="Social_media_linkss">

      <ul>
        <li>
          <a href="#">
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="44" viewBox="0 0 50 44" fill="none">
  <path d="M4.62041 33.54C5.25615 32.5665 5.66177 31.9443 6.06848 31.3228C6.65292 30.4297 6.90502 29.4541 5.90773 28.7777C4.9445 28.1243 4.10607 28.584 3.48077 29.5147C2.49532 30.9815 1.44614 32.4057 0.472644 33.8801C-0.538422 35.4115 0.125057 36.7117 1.96278 36.743C4.7928 36.7912 7.62605 36.7017 10.4538 36.7955C11.1713 36.8192 12.0393 37.137 12.5572 37.6206C19.291 43.9088 27.1392 45.5453 35.6621 42.4998C43.8639 39.5689 48.5432 33.332 49.7827 24.73C51.6375 11.8579 41.3715 0.265141 28.3858 0.00430392C18.1197 -0.201903 8.49179 7.04061 6.40968 17.7583C6.30461 18.2991 6.26388 18.8558 6.2345 19.4073C6.18344 20.3641 6.62281 20.9813 7.60286 21.0481C8.622 21.1176 9.14447 20.6206 9.29022 19.5609C9.97343 14.593 12.3235 10.5614 16.162 7.338C24.0413 0.721387 36.6158 2.14213 42.8454 10.3914C49.6623 19.4187 47.56 31.6436 38.1306 37.8063C30.6255 42.7113 20.5125 41.4806 14.3802 34.8391C13.5022 33.8881 12.6064 33.487 11.3528 33.5233C9.23947 33.5844 7.12307 33.54 4.62041 33.54Z" fill="white"/>
  <path d="M34.1107 27.4265C32.7188 29.1336 31.1328 29.3889 29.7 28.0292C27.0642 25.5279 24.4992 22.9475 21.9935 20.3155C20.732 18.9904 20.8863 17.5757 22.25 15.9365C22.702 16.3157 23.1048 16.8988 23.6196 17.0252C24.3093 17.1943 25.3613 17.2838 25.7449 16.8979C26.1049 16.5355 25.7994 15.5101 25.7799 14.7767C25.7789 14.7394 25.7193 14.6968 25.6788 14.6685C24.4199 13.7903 23.8426 11.7555 21.8687 12.13C20.3235 12.4231 18.1947 15.6126 18.0569 17.4284C17.8911 19.6131 18.4954 21.4487 20.1379 23.0054C22.519 25.2621 24.7907 27.6345 27.0993 29.9672C30.1764 33.0764 34.91 32.5237 37.6895 28.4236C37.9722 28.0066 37.7447 26.9367 37.3797 26.4509C36.5991 25.4116 35.672 24.4116 34.607 23.686C34.0643 23.3162 32.9277 23.2862 32.3545 23.6158C31.4631 24.1283 31.6498 25.1028 32.3365 25.8233C32.8899 26.4038 33.5267 26.9044 34.1107 27.4265Z" fill="white"/>
</svg>
          </a>
        </li>
        <li>
          <a href="#"><svg xmlns="http://www.w3.org/2000/svg" width="50" height="38" viewBox="0 0 50 38" fill="none">
  <path d="M46.6923 10.1798C43.9438 12.0717 41.5713 13.6992 39.2045 15.335C34.8704 18.3308 30.5662 21.3714 26.174 24.2793C25.6292 24.6399 24.4203 24.6134 23.8649 24.2451C19.2037 21.1537 14.6219 17.9425 10.0196 14.7625C9.05424 14.0956 8.58219 13.184 9.30648 12.1498C10.058 11.0766 11.1208 11.3692 12.0261 11.9906C15.9273 14.6685 19.8033 17.3832 23.6907 20.0812C24.1273 20.3842 24.5783 20.6664 25.0676 20.9877C31.0732 16.8311 37.0398 12.6991 43.0094 8.57153C44.2476 7.71539 45.4558 6.8032 46.7595 6.06001C47.399 5.69548 48.4477 5.28951 48.9111 5.56803C49.4867 5.91393 49.928 6.94327 49.9427 7.68691C50.0335 12.3199 49.9893 16.9555 49.9889 21.5903C49.9886 24.2849 49.9918 26.9795 49.9843 29.6741C49.9704 34.6274 47.6117 37.0285 42.6644 37.0384C32.4249 37.0587 22.1852 37.0524 11.9456 37.0381C9.90184 37.0353 7.84464 37.0784 5.81734 36.8696C2.30406 36.5076 0.111973 34.202 0.0720365 30.6344C-0.0184509 22.5517 -0.0335969 14.466 0.0841403 6.38384C0.140129 2.54362 2.96505 0.0185198 6.8184 0.0125003C16.8424 -0.00316349 26.8665 -0.015591 36.8901 0.0497827C37.6796 0.0548961 38.7953 0.456976 39.1838 1.05091C39.9945 2.29054 39.0295 3.46222 37.3118 3.4795C34.4019 3.50882 31.4914 3.48766 28.5813 3.48766C21.3597 3.48766 14.1381 3.48494 6.91653 3.48915C4.50398 3.49051 3.409 4.59603 3.40706 7.04379C3.40098 14.6965 3.40052 22.3492 3.40603 30.0019C3.40784 32.5189 4.5056 33.6404 7.04067 33.643C19.0587 33.6553 31.0767 33.6545 43.0947 33.6433C45.2379 33.6413 46.6384 32.6161 46.661 30.7332C46.7412 24.0607 46.6923 17.3867 46.6923 10.1798Z" fill="white"/>
</svg></a>
        </li>
      </ul>

      <p className="copyrights">
      ergosumdeus © 2024
      </p>
        </div>
      </div>
    </>
  );
};

export default Homepage;

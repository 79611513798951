import React from 'react';



const Loader = () => {
 
  return (
    <>
    <div className="pos-center">
    <div className="loader"></div>
    </div>
        
    </>
  );
}

export default Loader;